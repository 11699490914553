import React from "react"
import Layout from '../components/layout'
import Link from '../styledComponents/Link'

const Contact = () => (
  <Layout>
    <small>
      <Link href="mailto:you@munderstand.me">
        Something's missing in your life, what if it's been me this whole time?
      </Link>
    </small>
  </Layout>
)

export default Contact;
